import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import { LuFilter, LuFilterX } from "react-icons/lu";
import Loading from '../Loading';
import ExcelView from '../../components/ExcelView';
import { exportExcel } from '../../components/export';
import DownloadIcon from '../../content/images/icons/download.png';
import { ImCheckboxChecked } from 'react-icons/im';

export default function Home() {
    const navigate = useNavigate();
    const context = useContext(Context);

    const [view, setView] = useState('list');
    const [tableData, setTableData] = useState<any>(null);
    const [projects, setProjects] = useState<any>(null);
    const [filterOn, setFilterOn] = useState(false);

    // Filters
    const [search, setSearch] = useState('');
    const [fase, setFase] = useState('');
    const [paid, setPaid] = useState('');
    const [requesitosLevantados, setRequesitosLevantados] = useState('');

    void tableData;

    useEffect(() => {
        if (context?.projects) {
            let result = context?.projects;
            // Filter by search
            if (search) {
                result = result.filter((ele: any) =>
                    (ele?.garage).toLowerCase().includes(search.toLowerCase()) ||
                    (ele?.name).toLowerCase().includes(search.toLowerCase()));
            }
            // Filter by other filters
            if (filterOn) {
                result = result.filter((ele: any) =>
                    (fase ? ele?.faseDoProjeto.toString() === fase : true) &&
                    (paid ? (ele?.paid === ele?.orcamento ? '1' : '0') === paid : true) &&
                    (requesitosLevantados ? ele?.requesitosLevantados === (requesitosLevantados === '1' ? true : false) : true)
                );
            }
            setProjects(result);
        }
    }, [search, paid, fase, filterOn, requesitosLevantados, context?.projects]);

    function formateDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const header = [
        "name",
        "company",
        "projectManager",
        "faseDoProjeto",
        "deadline",
        "orcamento",
        "tipoOrcamento",
        "toPay",
        "paid",
        "services",
        "agents",
        "requesitosLevantados",
        "overview",
        "chanceOfFunds",
        "funds",
        "projectLink",
        "link",
    ];

    function handleExport() {
        const exportData = projects?.map((project: any) => {
            return {
                Nome: project?.name,
                "Empresa": project?.company,
                "Project Manager": project?.projectManager,
                "Fase do projeto": project?.faseDoProjeto,
                Deadline: project?.deadline,
                Orcamento: project?.orcamento,
                "Falta pagar": project?.toPay,
                "Foi pago": project?.paid,
                "Tipo de orcamento": project?.tipoOrcamento,
                "Requesitos Levantados": project?.requesitosLevantados,
                "Chance de Fundos": project?.chanceOfFunds,
                Fundos: project?.funds,
                Overview: project?.overview,
                "Link do projeto": project?.projectLink,
                "Pasta partilhada": project?.link,
                "Data de criação": formateDate(project?.creationDate),
            }
        });
        exportExcel(exportData, 'projetos-clientes');
    }

    if (!projects) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full min-h-screen p-8'>
            <div className='flex flex-col items-end justify-between w-full gap-4 cinco:items-center cinco:flex-row'>
                <button onClick={() => navigate("/project/create")} className='small-button bg-[var(--primary)]'>+ Novo Projeto</button>
                <button onClick={() => navigate('checklist')} className='small-button bg-[var(--blue)]'>Ver Check List <ImCheckboxChecked /></button>
            </div>
            <div className='flex flex-col gap-4 mt-8'>
                <div className='flex items-center gap-4'>
                    <input
                        type="text"
                        placeholder='Pesquisa'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className='input'
                    />
                    <button className='p-2 border-[1px] border-[var(--black)] rounded-full cursor-pointer' onClick={() => setFilterOn(!filterOn)}>
                        {filterOn ? <LuFilterX /> : <LuFilter />}
                    </button>
                </div>
                {
                    <div className={`flex items-center gap-8 overflow-y-hidden overflow-x-auto transition-all duration-500 ease ${filterOn ? 'max-h-[5rem]' : 'max-h-0'}`}>
                        <div className="flex flex-col gap-2">
                            <p>Fase do projeto</p>
                            <select value={fase} onChange={(e) => setFase(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="1">Adjudicação/Estrutura</option>
                                <option value="2">Desenvolvimento</option>
                                <option value="3">Testes/Lançamento</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Requisitos Levantados</p>
                            <select value={requesitosLevantados} onChange={(e) => setRequesitosLevantados(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p>Pago</p>
                            <select value={paid} onChange={(e) => setPaid(e.target.value)} className='filter'>
                                <option value="">Selecionar</option>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center justify-between gap-4 my-6 seis:flex-row'>
                <div className='relative flex items-center w-[16rem] h-8 border-2 border-[var(--black)] rounded-full justify-evenly'>
                    <div className={`absolute top-0 ${view === 'list' ? 'left-0' : 'left-1/2'} h-full w-1/2 bg-[var(--stats)] rounded-full z-[-1] transition-all duration-300`}></div>
                    <p onClick={() => setView('list')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Lista</p>
                    <p onClick={() => setView('excel')} className='w-1/2 text-[.8rem] text-center cursor-pointer'>Excel</p>
                </div>
                <div className='flex flex-col gap-4 quatro:flex-row'>
                    <button onClick={handleExport} className='small-button bg-[var(--primary)] gap-4'>
                        Exportar
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
            {
                view === 'list' ?
                    <div className='flex flex-col gap-10 mt-6 mil:flex-row'>
                        <div className='w-full'>
                            <h2 className=' text-[1.5rem]'>Projetos Ativos</h2>
                            <p className='mb-8'>{projects ? `Total: ${projects?.filter((project: any) => project.faseDoProjeto !== 4 && !project.lead).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!projects || projects?.length === 0) ? <p>Nenhum resultado</p> :
                                        projects?.filter((project: any) => project.faseDoProjeto !== 4 && !project.lead)?.map((project: any) => (
                                            <div
                                                key={project?.id}
                                                className='big-button style2 bg-purple'
                                                onClick={() => navigate(`/project/${project?.company}-${project?.name}`)}
                                            >
                                                <div>
                                                    <p className='text-[1.2rem]'>{project?.name}</p>
                                                    <p>De: {project?.company}</p>
                                                </div>
                                                <p>{formateDate(project?.deadline)}</p>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className='w-full'>
                            <h2 className='text-[1.5rem]'>Projetos Concluídos</h2>
                            <p className='mb-8'>{projects ? `Total: ${projects?.filter((project: any) => project.faseDoProjeto === 4 && !project.lead).length}` : ""}</p>
                            <div className='flex flex-col gap-4'>
                                {
                                    (!projects || projects?.filter((project: any) => project.faseDoProjeto === 4)?.length === 0) ? <p>Nenhum resultado</p> :
                                        projects?.filter((project: any) => project.faseDoProjeto === 4 && !project.lead)?.map((project: any) => (
                                            <div
                                                key={project?.id}
                                                className='big-button style2 bg-gray'
                                                onClick={() => navigate(`/project/${project?.company}-${project?.name}`)}
                                            >
                                                <div>
                                                    <p className='text-[1.2rem]'>{project?.name}</p>
                                                    <p>De: {project?.company}</p>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <ExcelView
                        header={header}
                        tableData={projects}
                        setTableData={setTableData}
                        data="projects"
                    />
            }
        </div>
    );
}

