import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../services/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function ArticlePage() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const pt =
    localStorage.getItem("lang") !== null &&
    localStorage.getItem("lang") === "pt";

  const [loading, setLoading] = useState<boolean>(true);
  const edit = window.location.pathname.includes("article");
  const [article, setArticle] = useState<any>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        let docRef = doc(db, "blog", id);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle(docSnap.data());
        }
      } catch (error) {
        console.error("Error getting document: ", error);
      }
      setLoading(false);
    }
    if (id) fetchData();
    else {
      setArticle({
        title: "Titulo do Artigo",
        description: "Descrição do Artigo",
        author: "Autor do Artigo",
        publishDate: new Date().toLocaleDateString(),
        image: "https://via.placeholder.com/200",
        article: "<p>Texto do Artigo</p>",
        draft: false,
      });
      setLoading(false);
    }
  }, [id]);

  const handleInputChange = (field: string, value: any) => {
    setArticle((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const uploadImage = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.click();
    input.onchange = () => {
      const file: any = input.files?.[0];
      if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
        setImageFile(file);
        const imageURL = URL.createObjectURL(file);
        setArticle((prev: any) => ({
          ...prev,
          image: imageURL,
        }));
      } else {
        console.error("Only png and jpg/jpeg allowed.");
        alert("Apenas png e jpg/jpeg são permitidos.");
      }
    };
  };

  const uploadImageToStorage = async (file: File) => {
    try {
      const storageRef = ref(storage, `blog/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      alert("Erro ao fazer upload da imagem.");
      return null;
    }
  };

  const action = async () => {
    // Validate article
    if (
      !article.title ||
      !article.description ||
      !article.author ||
      (!imageFile && !edit) ||
      !article.article
    ) {
      alert("Preencha todos os campos!");
      return;
    }
    setLoading(true);
    const newArticle = article;
    try {
      if (imageFile) {
        const imageUrl = await uploadImageToStorage(imageFile);
        if (imageUrl) {
          newArticle.image = imageUrl;
        }
      }

      if (edit) {
        const docRef = doc(db, "blog", id);
        await updateDoc(docRef, article);
        alert("Artigo atualizado com sucesso!");
        navigate("/blog");
      } else {
        const collectionRef = collection(db, "blog");
        await addDoc(collectionRef, article);
        alert("Artigo criado com sucesso!");
        navigate("/blog");
      }
    } catch (error) {
      console.log(newArticle);
      console.error("Error during document action: ", error);
      alert(edit ? "Erro ao atualizar o artigo!" : "Erro ao criar o artigo!");
    }
    setLoading(false);
  };

  const goBack = () => {
    if (
      window.confirm(
        "Deseja mesmo sair? As alterações não salvas serão perdidas."
      )
    )
      navigate(-1);
  };

  if (!article) {
    return (
      <div className="relative flex items-center justify-center w-full min-h-screen">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div className="w-full bg-[#f0f0f0] min-h-screen flex flex-col px-4 gap-4">
      <div className="flex items-center justify-between w-full gap-4 pt-4">
        <p
          onClick={goBack}
          className="relative text-left cursor-pointer"
        >
          <span className="text-purple">←</span> &ensp; <span>Voltar</span>
        </p>
        <button
          onClick={action}
          disabled={loading}
          className="rounded-full bg-green small-button"
        >
          {edit ? "Atualizar" : "Criar"} Artigo
        </button>
      </div>
      <div className="relative flex flex-col w-full gap-6 text-left">
        <div className="flex flex-col w-full gap-6 text-left">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              placeholder=""
              checked={article?.draft}
              onChange={() =>
                setArticle((prev: any) => ({
                  ...prev,
                  draft: !prev.draft,
                }))
              }
              className="accent-[var(--primary)] w-6 h-6"
            />
            <p>Rascunho</p>
          </div>
          <input
            type="text"
            value={article.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            className="text-[2rem] font-poppins text-[var(--primary)] w-full bg-[transparent]"
          />
          <textarea
            value={article.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            className="text-[1.5rem] font-bold w-full bg-[transparent] resize-none"
          />
          <div>
            <p>
              {pt ? "De:" : "By:"}{" "}
              <input
                type="text"
                value={article.author}
                onChange={(e) => handleInputChange("author", e.target.value)}
                className="bg-[transparent] font-bold"
              />
            </p>
            <p>
              Publicado no dia{" "}
              <span className="font-bold">{article.publishDate}</span>
            </p>
          </div>
          <div>
            <button
              onClick={uploadImage}
              className="bg-[var(--primary)] rounded-full text-white px-4 py-2"
            >
              Upload Image
            </button>
          </div>
          <img
            src={article.image}
            alt="imagem do artigo"
            className="w-full max-h-[20rem] object-cover"
          />
        </div>
        <div className="article-editor relative py-[2rem] w-full border-y-[1px] border-[grey] flex flex-col items-center min-h-[25rem] gap-6 text-left">
          <CKEditor
            editor={ClassicEditor}
            data={article.article}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              handleInputChange("article", data);
            }}
          />
        </div>
      </div>
    </div>
  );
}
