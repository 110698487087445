import React, { useContext, useEffect, useState } from 'react';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { ImBin } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/Context';
import Loading from '../Loading';
import toast, { Toaster } from "react-hot-toast";
import { IoDocumentText } from 'react-icons/io5';
import { updateData } from '../../services/server';

export default function Settings() {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [users, setUsers] = useState<any>(null);
    const [services, setServices] = useState<any>(null);
    const [areas, setAreas] = useState<any>(null);
    const [techs, setTechs] = useState<any>(null);
    const [tipos, setTipos] = useState<any>(null);
    const [channels, setChannels] = useState<any>(null);
    const [partnerTypes, setPartnerTypes] = useState<any>(null);

    const [tipoSelected, setTipoSelected] = useState('Inactive');

    const [service, setService] = useState('');
    const [area, setArea] = useState('');
    const [tech, setTech] = useState('');
    const [channel, setChannel] = useState('');
    const [partnerType, setPartnerType] = useState('');
    const [userType, setUserType] = useState('');
    const [user, setUser] = useState<any>(null);

    const toggleList: any = {
        Inactive: 'left-0',
        HR: 'left-[16.6%]',
        Sales: 'left-[33.3%]',
        Agent: 'left-[50%]',
        Client: 'left-[66.6%]',
        Partner: 'left-[83.3%]',
    };

    useEffect(() => {
        if (context) {
            if (tipoSelected === 'Inactive')
                setUsers(context?.inactive);
            else if (tipoSelected === 'HR')
                setUsers(context?.rh);
            else if (tipoSelected === 'Sales')
                setUsers(context?.sales);
            else if (tipoSelected === 'Agent')
                setUsers(context?.agents.filter((ele: any) => !ele?.lead));
            else if (tipoSelected === 'Client')
                setUsers(context?.clients.filter((ele: any) => !ele?.lead));
            else if (tipoSelected === 'Partner')
                setUsers(context?.partners.filter((ele: any) => !ele?.lead));
            setTipos(context?.settings.types);
            setServices(context?.settings.services);
            setAreas(context?.settings.areas);
            setTechs(context?.settings.techs);
            setChannels(context?.settings.channels);
            setPartnerTypes(context?.settings.partnerTypes);
        }
    }, [context, context?.admins, context?.rh, context?.sales, context?.inactive, context?.agents, context?.clients, tipoSelected]);

    async function addUser() {
        try {
            await updateData(toast, 'users', user?.email, {...user, type: userType, lead: false}, 'Agente atualizado com sucesso.', () => { });
            toast.success(`Utilizador atualizado com sucesso.`);
            navigate('/home');
        } catch (err) {
            toast.error('Utilizador já existe.');
            console.error(err);
        }
        // TODO: Enviar email com credenciais
    }

    async function addItem(listName: string, item: any) {
        if (!item) {
            return toast.error('Preencha o campo para adicionar o item.');
        }
        try {
            const docRef = doc(db, 'admin', 'geral');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let items = docSnap.data()[listName];
                items = [...items, item];
                await updateDoc(docRef, { [listName]: items })
                    .then(() => {
                        toast.success(`Item adicionado a ${listName} com sucesso.`);
                        updateState(listName, items);
                        setChannel('');
                        setPartnerType('');
                        setArea('');
                        setService('');
                        setTech('');
                    });
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error(`Erro ao adicionar item a ${listName}.`);
        }
    }

    async function deleteItem(listName: string, index: any) {
        if (!window.confirm(`Tem a certeza que pretende apagar este item de ${listName}?`)) {
            return;
        }
        try {
            const docRef = doc(db, 'admin', 'geral');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let items = docSnap.data()[listName];
                items = [...items.slice(0, index), ...items.slice(index + 1)];
                await updateDoc(docRef, { [listName]: items })
                    .then(() => {
                        toast.success(`Item removido de ${listName} com sucesso.`);
                        updateState(listName, items);
                    });
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error(`Erro ao remover item de ${listName}.`);
        }
    }

    function updateState(listName: string, items: any) {
        switch (listName) {
            case 'services':
                setServices(items);
                break;
            case 'areas':
                setAreas(items);
                break;
            case 'techs':
                setTechs(items);
                break;
            case 'channels':
                setChannels(items);
                break;
            case 'partnerTypes':
                setPartnerTypes(items);
                break;
        }
    }

    async function openUserPage(user: any) {
        if (user?.type === 'Agent' && user?.lead) {
            navigate(`/agent/lead/${user?.id}`);
        } else if (user?.type === 'Agent' && !user?.lead) {
            navigate(`/agent/${user?.id}`);
        } else if (user?.type === 'Client' && user?.lead) {
            navigate(`/client/lead/${user?.id}`);
        } else if (user?.type === 'Client' && !user?.lead) {
            navigate(`/client/${user?.id}`);
        } else if (user?.type === 'Partner' && !user?.lead) {
            navigate(`/partner/${user?.id}`);
        } else if (user?.type === 'Partner' && user?.lead) {
            navigate(`/partner/lead/${user?.id}`);
        } else if (user?.type === 'inactive') { 
            setUser(user);
        }
    }

    function openMeet() {
        if (!context?.settings?.assembleiaLink) {
            return toast.error('Não há link de reunião definido.');
        }
        window.open(context?.settings?.assembleiaLink, '_blank');
    }

    if (!users || !services || !tipos || !areas || !techs) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full p-4 quatrocentos:p-8'>
            <Toaster />
            <div className='flex flex-col items-center justify-between w-full gap-4 mb-8 sete:flex-row'>
                <h1 className='text-[1.5rem] font-bold'>ADMIN</h1>
                <div className='flex flex-col items-center gap-4 cinco:flex-row'>
                    <button onClick={openMeet} className='justify-center small-button bg-[var(--stats)] hidden quatro:flex !text-[var(--black)]'><p className='w-full text-center'>Assembleia Geral</p></button>
                </div>
            </div>
            <div className='flex gap-4'>
                <button className='space-x-4 big-button style2 bg-purple' onClick={() => navigate("/templates")}>
                    <IoDocumentText className='text-2xl' />
                    <p>Emails template</p>    
                </button>
                <button className='space-x-4 big-button style2 bg-green' onClick={() => navigate("/blog")}>
                    <IoDocumentText className='text-2xl' />
                    <p>Blog</p>    
                </button>
            </div>
        </div>
    );
}

