import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ArticleCard from "../../components/articleCard";

export default function Admin() {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let colRef = collection(db, "blog");
        let docsSnap = await getDocs(colRef);
        let data: any = [];
        docsSnap.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        setArticles(data);
      } catch (error) {
        console.error("Error getting document: ", error);
      }
    }
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, []);

  return (
    <div className="w-full p-8 min-h-screen bg-[#f0f0f0] space-y-10">
      <p
        onClick={() => navigate(-1)}
        className="relative w-full text-left cursor-pointer"
      >
        <span className="text-purple">←</span> &ensp; <span>Voltar</span>
      </p>
      <div className="flex items-center justify-between gap-4">
        <h1 className="text-[1.2rem] cinco:text-[1.5rem] font-bold">
          Painel do Blog
        </h1>
      </div>
      <div className="space-y-4">
        <h2 className="text-[1.2rem]">Artigos</h2>
        {isLoading ? (
          <div className="flex items-center justify-center w-full min-h-screen">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className="grid oitocentos:grid-cols-[repeat(auto-fill,18rem)] gap-8">
            {articles &&
              articles.map((ele: any, index: number) => {
                return <ArticleCard key={index} ele={ele} />;
              })}
            <button onClick={() => navigate("/blog/create")}>
              <div className="w-full oitocentos:w-[18rem] h-[18rem] font-bold bg-white hover:brightness-90 rounded-[25px] justify-center items-center flex place-self-center">
                + criar
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
