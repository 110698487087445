
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
    const navigate = useNavigate();
    return (
        <div className='relative flex items-center justify-center w-full min-h-screen'>
            <p className='absolute top-8 left-8 text-[1rem] cursor-pointer' onClick={() => navigate(-1)}><span className='text-purple'>←</span> &ensp; <span>Voltar</span></p>
            <CircularProgress color="inherit" />
        </div>
    );
}

